import revive_payload_client_uXZlcmQNnP from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__pz5leq2uh6q2wo4rf65ffthmxq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qxhpxsuLtf from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__pz5leq2uh6q2wo4rf65ffthmxq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qhy48ZfQaQ from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__pz5leq2uh6q2wo4rf65ffthmxq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_0zBa5QNbHZ from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__pz5leq2uh6q2wo4rf65ffthmxq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_E04mOW5zhO from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__pz5leq2uh6q2wo4rf65ffthmxq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_z1Pg2hD1MF from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__pz5leq2uh6q2wo4rf65ffthmxq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fQz32B0Trt from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__pz5leq2uh6q2wo4rf65ffthmxq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/checkup/checkup/apps/operator/.nuxt/components.plugin.mjs";
import prefetch_client_8Py66skUzm from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__pz5leq2uh6q2wo4rf65ffthmxq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import supabase_client_Vu6b6nytQn from "/home/runner/work/checkup/checkup/apps/operator/src/plugins/supabase.client.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/checkup/checkup/apps/operator/src/plugins/sentry.client.ts";
import i18n_YCWKwfVXF6 from "/home/runner/work/checkup/checkup/apps/operator/src/plugins/i18n.ts";
export default [
  revive_payload_client_uXZlcmQNnP,
  unhead_qxhpxsuLtf,
  router_qhy48ZfQaQ,
  payload_client_0zBa5QNbHZ,
  navigation_repaint_client_E04mOW5zhO,
  check_outdated_build_client_z1Pg2hD1MF,
  chunk_reload_client_fQz32B0Trt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8Py66skUzm,
  supabase_client_Vu6b6nytQn,
  sentry_client_KAXFuL2wum,
  i18n_YCWKwfVXF6
]